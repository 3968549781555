import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width={43}
      height={57}
      viewBox="0 0 43 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.281 18.298H0c.305 4.094 1.093 8.273 1.982 13.02 1.733 9.237 4.424 19.058 8.713 23.631 1.407 1.503 4.832.235 8.502-3.739 4.044-4.384 4.409-6.081 4.409-6.081-1.853-3.146-3.495-7.894-4.973-13.45-1.608-6.052-2.042-10.386-2.352-13.381z"
        fill="#F93434"
      />
      <path
        d="M23.247 47.74c-4.493 5.492-6.246 6.256-7.933 7.29-.72.439-1.618.709-2.112.858-.09.025-.065.15.025.15l21.124-.01c.225 0 .56-.095.739-.225 1.867-1.298 3.066-3.155 4.389-5.756C44.516 40.16 42.774 6.34 42.434.079c-.005-.09-.13-.11-.16-.025-2.191 5.762-8.886 35.284-19.027 47.686z"
        fill="#19BA79"
      />
    </svg>
  )
}

export default SvgComponent
